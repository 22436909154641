.my-orders-theme2 {
  border: none;
  width: 100%;
  height: 100%;
  background: rgba(244, 244, 244, 100);
  .my-order-title {
    display: flex;
    justify-content: center;
    height: 52px;
    margin-top: 12px;
    .order-status-name {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      color: #282828;
    }
    .total-orders {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      color: #282828;
    }
  }
  .my-orders-nav {
    padding: 0px 16px;
    background: #ffffff;
    border-radius: 20px;
  }
  /* Hide scrollbar for Chrome, Safari and Opera */
  .my-orders-status-list::-webkit-scrollbar {
    display: none;
  }
  .my-orders-status-list {
    width: 100%;
    height: 72px;
    padding: 12px 0px;
    background: #ffffff;
    overflow-x: scroll;
    -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
    scrollbar-width: none; /*Hide scrollbar for Firefox */

    .my-order-status-list-rd {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      margin-right: 16px;

      .my-order-status-rd {
        border: none;

        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #000000;
        padding: 12px 16px;
        border-radius: 30px;
        white-space: nowrap;
        height: 100%;

        &::before {
          width: 0px;
        }
      }
      .ant-radio-button-wrapper.ant-radio-button-wrapper-checked.my-order-status-rd {
        border-radius: unset;
        color: #ffffff;
        background: #db4d29;
        border-radius: 30px;
        border: none;
      }
    }
  }

  .my-orders-list {
    padding-top: 12px;
    max-height: 500px;
    overflow-y: scroll;

    .loading-orders {
      width: 100%;
      display: flex;
      justify-content: center;
      img {
        width: 50px;
        height: 50px;
      }
    }
    .no-order {
      width: 100%;
      height: 340px;
      border-radius: 20px;
      display: flex;
      align-items: center;
      background-image: url(../../../../assets/images/background_my_order.png);
      background-repeat: no-repeat;
      background-position: right;
      background-size: cover;
      .order-description {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 24px;
        margin-left: 12%;
        .not-order-yet {
          color: #282828;
          font-size: 32px;
          font-style: normal;
          font-weight: 700;
          line-height: 44px;
          text-align: center;
        }
        .order-btn {
          padding: 12px 16px;
          border-radius: 12px;
          background-color: #DB4D29;
          text-decoration-line: none;
          color: #FFF;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px; 
        }
      }
    }
  }
}

/* Mobile - ant-xs */
@media (max-width: 575px) {
  .my-orders-theme2 {
    .my-orders-status-list {
      width: 100%;
      height: 76px;
      border-radius: 20px;
      padding: 12px 0px;
      background: #ffffff;
      overflow-x: scroll;

      .my-order-status-list-rd {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;

        .my-order-status-rd {
          border: none;

          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          display: flex;
          align-items: center;
          text-align: center;
          color: #000000;
          padding: 12px 16px;
          border-radius: 30px;

          &::before {
            width: 0px;
          }
        }
        .ant-radio-button-wrapper.ant-radio-button-wrapper-checked.my-order-status-rd {
          border-radius: unset;
          color: #db4d29;
          background: rgba(219, 77, 41, 0.1);
          border-radius: 30px;
          border: none;
        }
      }
    }

    .my-orders-list {
      padding-top: 12px;
      overflow-y: scroll;

      .loading-orders {
        width: 100%;
        display: flex;
        justify-content: center;
        img {
          width: 50px;
          height: 50px;
        }
      }
      .no-order {
        width: 100%;
        height: 565px;
        border-radius: 20px;
        display: flex;
        align-items: flex-start;
        background-image: url(../../../../assets/images/background_my_order_mobile.png);
        background-repeat: no-repeat;
        background-position: right;
        background-size: cover;
        .order-description {
          width: 100%;
          margin-top: 80px;
          margin-left: 0;
        }
      }
    }
  }
}
