.delivery-address-selector-theme2 {
  .delivery-address-header-box {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 229px;
    height: 56px;
    background: #d3411b;
    border-radius: 50px;

    .left-box {
      cursor: pointer;

      .img-box {
        padding-left: 8px;
      }
    }

    .middle-box {
      width: 100%;
      margin-left: 8px;
      cursor: pointer;

      .text-delivery-to {
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        padding-top: 8px;
      }

      .text-delivery-address {
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        max-width: 133px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .right-box {
      cursor: pointer;
      width: 42px;
      display: flex;
      justify-content: center;
    }

    .arrow-pickup {
      width: 24px;
      padding: 0 8px 0px 0 !important;
    }
  }
}

.modal-delivery-address-selector-theme2 {
  width: 800px !important;

  .ant-modal-content {
    background: transparent;
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
    padding: 0px;

    .ant-modal-close {
      display: none;
    }

    .ant-modal-body {
      .tabs-control {
        overflow: hidden;
        .ant-tabs-nav {
          margin: 0px;

          .ant-tabs-nav-wrap {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 16px 24px;
            gap: 12px;
            width: 100%;
            height: 97px;
            background: rgba(255, 255, 255, 0.5);
            backdrop-filter: blur(7.5px);
            border-radius: 20px 20px 0px 0px;
            flex: none;
            order: 0;
            align-self: stretch;
            flex-grow: 0;

            .ant-tabs-nav-list {
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              gap: 12px;
              height: 65px;
              background: #ffffff;
              border-radius: 32px;

              .ant-tabs-tab {
                padding: 12px;
                margin: 0px;

                .ant-tabs-tab-btn {
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  padding: 0px;
                  gap: 12px;
                  height: 40px;

                  .label-tabs {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 10px;

                    .label-text {
                      height: 32px;
                      font-style: normal;
                      font-weight: 700;
                      font-size: 20px;
                      line-height: 32px;
                      display: flex;
                      align-items: center;
                      color: #000000;
                    }
                  }
                }
              }

              .ant-tabs-tab-active {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 12px;
                gap: 10px;
                height: 65px;
                background: #db4d29;
                box-shadow: 4px 4px 15px rgba(255, 255, 255, 0.2);
                border-radius: 32px;

                .ant-tabs-tab-btn {
                  .label-tabs {
                    .label-text {
                      color: #ffffff;
                    }
                  }
                }
              }
            }
          }
        }

        .ant-tabs-content-holder {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 24px;
          background: #ececec;
          backdrop-filter: blur(25px);
          border-radius: 0px;
          border-bottom-left-radius: 20px;
          border-bottom-right-radius: 20px;

          .ant-tabs-content {
            .ant-tabs-tabpane {
              .delivery-address-style {
                padding: 24px;
                display: grid;
                gap: 24px;

                .body-container {
                  .delivery-select-address-input {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding: 18px 16px;
                    height: 60px;
                    background: rgba(255, 255, 255, 0.7);
                    border: 1px solid #e2e2e2;
                    border-radius: 12px;

                    .ant-input {
                      background-color: rgba(255, 255, 255, 0.7);
                      background: #f9f9f9; //styleName: Text 2 (Medium - 16);
                      font-size: 16px;
                      font-weight: 500;
                      line-height: 24px;
                      text-align: left;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.address-detail-popover-content {
  margin-top: 9px;
  width: 421px;
  height: 200px;
  padding-top: 12px;
  padding-bottom: 12px;
  .address-detail {
    cursor: pointer;
  }

  .top-content,
  .bottom-content {
    width: 100%;
    height: 50%;
    display: flex;
  }

  .top-content {
    width: 100%;
    justify-content: space-between;
    gap: 12px;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 12px;
    padding-bottom: 12px;
    .detail-left-box {
      width: 62px;
      display: flex;
      justify-content: center;

      .img-box {
      }
    }

    .detail-middle-box {
      width: 313px;
      .text-delivery-to {
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
      }

      .text-delivery-address {
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
      }
    }

    .detail-right-box {
      display: flex;
      justify-content: center;
      align-items: center;
      .icon {
      }
    }
  }

  .bottom-content {
    width: 100%;
    justify-content: space-between;
    gap: 12px;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 12px;
    padding-bottom: 12px;
    .detail-left-box {
      width: 62px;
      display: flex;
      justify-content: center;

      .img-box {
      }
    }

    .detail-middle-box {
      width: 313px;

      .text-delivery-to {
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
      }

      .text-delivery-address {
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
      }
    }

    .detail-right-box {
      display: flex;
      justify-content: center;
      align-items: center;
      .icon {
      }
    }
  }
}

.modal-delivery-address-selector-theme2.login {
  .ant-modal-content {
    margin-left: auto;
    margin-right: auto;
  }
}

.shopping-cart-warning-dialog-2 {
  .btn-shopping-cart-warning-continue {
    cursor: pointer;
    color: #ffffff;
    text-align: center;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;

    background: #db4d29;
    border-radius: 12px;
    padding: 12px 16px;
    gap: 16px;

    width: 210px;
    height: 48px;
  }

  .ant-modal-content .ant-modal-footer {
    padding-bottom: 39px;
  }

  .shopping-cart-warning-content {
    .text {
      padding: 5px;
      font-style: normal;
      text-align: center;
      font-weight: 400;
      font-size: 14px;

      color: #000000;

      p {
        padding-top: 15px;
      }
    }

    .branch-name {
      font-weight: bolder;
    }
  }
}

// Adapt mobile screen
@media screen and (max-width: 575px) {
  .delivery-address-selector-mobile-mode {
    position: relative;

    .delivery-address-selector-theme2 {
      position: unset;

      .delivery-address-header-box {
        width: 100%;
        margin: auto;
      }
    }
  }

  .modal-delivery-address-selector-theme2 {
    .ant-modal-content {
      background: transparent;
      padding: 0px;

      .ant-modal-close {
        display: none;
      }

      .ant-modal-body {
        .tabs-control {
          .ant-tabs-nav {
            margin: 0px;

            .ant-tabs-nav-wrap {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              padding: 16px 24px;
              gap: 12px;
              width: 100%;
              height: 97px;
              background: rgba(255, 255, 255, 0.5);
              backdrop-filter: blur(7.5px);
              border-radius: 20px 20px 0px 0px;
              flex: none;
              order: 0;
              align-self: stretch;
              flex-grow: 0;

              .ant-tabs-nav-list {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                gap: 12px;
                height: 65px;
                background: #ffffff;
                border-radius: 32px;

                .ant-tabs-tab {
                  padding: 12px;
                  margin: 0px;

                  .ant-tabs-tab-btn {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding: 0px;
                    gap: 12px;
                    height: 40px;

                    .label-tabs {
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      gap: 10px;

                      .label-text {
                        height: 32px;
                        font-style: normal;
                        font-weight: 700;
                        font-size: 20px;
                        line-height: 32px;
                        display: flex;
                        align-items: center;
                        color: #000000;
                      }
                    }
                  }
                }

                .ant-tabs-tab-active {
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                  padding: 12px;
                  gap: 10px;
                  height: 65px;
                  background: #db4d29;
                  box-shadow: 4px 4px 15px rgba(255, 255, 255, 0.2);
                  border-radius: 32px;

                  .ant-tabs-tab-btn {
                    .label-tabs {
                      .label-text {
                        color: #ffffff;
                      }
                    }
                  }
                }
              }
            }
          }

          .ant-tabs-content-holder {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 24px;
            background: #ececec;
            backdrop-filter: blur(25px);
            border-radius: 0px;
            border-bottom-left-radius: 20px;
            border-bottom-right-radius: 20px;

            .ant-tabs-content {
              .ant-tabs-tabpane {
                .delivery-address-style {
                  padding: 16px;
                  padding-top: 24px;
                  display: grid;
                  gap: 24px;

                  .body-container {
                    .delivery-select-address-input {
                      display: flex;
                      flex-direction: row;
                      align-items: center;
                      padding: 18px 16px;
                      height: 60px;
                      background: rgba(255, 255, 255, 0.7);
                      border: 1px solid #e2e2e2;
                      border-radius: 12px;

                      .ant-input {
                        background-color: rgba(255, 255, 255, 0.7);
                        background: #f9f9f9;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .modal-delivery-address-selector-theme2.login {
    .ant-modal-content {
      width: 100%;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .delivery-address-selector-theme2 {
    margin-top: 0; //Will remove later
    margin-left: 0; //Will remove later
    position: absolute; //Will remove later
    top: 150px; //Will remove later
    z-index: 1000; //Will remove later

    .delivery-address-header-box {
      width: 100%;
      max-width: 428px;
      position: relative;

      .left-box {
        width: 54px;

        .img-box {
          padding-left: 8px;
          width: 40px;
          height: 40px;
        }
      }

      .middle-box {
        width: 100%;
        flex: 1 1;

        .text-delivery-address {
          max-width: 200px;
        }
      }

      .right-box {
        width: 42px;
        display: flex;
        justify-content: center;
        flex: 0 !important;
      }

      .popover-delivery-address-detail-theme2 {
        max-width: 100%;
        padding-left: 12px;

        .address-detail-popover-content {
          width: 100%;
        }
      }
    }
  }
}
