.checkout-order-item-theme-2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  .order-item {
    display: flex;
    width: 100%;
    flex-wrap: wrap;

    .order-item-detail {
      width: calc(100% - 104px);
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;

      .order-item-infomation {
        display: flex;
      }
    }
    .order-item-img {
      width: 104px;
    }

    .order-item-image {
      cursor: pointer;
      min-width: 80px;
      height: 80px;
      margin: 0 12px;
      margin-bottom: 12px;
      .fnb-display-image .display-image {
        border-radius: 12px;
      }
      .fnb-display-image .flash-sale-border {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-width: 2px;
        border-bottom-width: 13px;
        border-radius: 12px;
      }
      .fnb-display-image .flash-sale {
        width: 80%;
        bottom: -2.5px;
        left: 3px;
      }
    }
    .order-item-info {
      width: 40%;
      display: flex;
      .name-info {
        .name {
          cursor: pointer;

          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 24px;
          color: #282828;
          margin-bottom: 4px;
          padding-right: 20px;
        }
        .discount {
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 20px;
          color: #ffffff;
          padding: 4px 8px;
          background-color: #ffa318;
          border-radius: 20px;
          width: fit-content;
          margin-bottom: 12px;
        }
        .options {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: #959595;
          margin-bottom: 8px;
        }
      }
    }

    .order-item-price {
      width: 20%;
      display: flex;
      flex-direction: column;

      font-style: normal;
      .price-after-discount {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #282828;
      }
      .price {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #959595;
        text-decoration: line-through;
      }
    }

    .order-item-quantity {
      width: 20%;
      display: flex;
      .quantity-icon:hover {
        cursor: pointer;
      }
      .quantity {
        min-width: 50px;
        text-align: center;
        user-select: none;
      }
      .out-of-stock {
        cursor: not-allowed !important;
      }
    }

    .order-item-total {
      width: 20%;
      display: flex;
      justify-content: space-between;
      .total-amount {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #282828;
        user-select: none;
      }
      .delete-icon {
        cursor: pointer;
        margin-right: 12px;
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }

  .cartMessageCheckout {
    min-height: 24px;
    background-color: #f9f9f9;
    border-radius: 8px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #282828;
    margin: 0px 60px 13px 0;
    padding: 12px 8px 12px 8px;

    .messageIconCheckout {
      margin: 0px 11px 0px 0px;
      width: 16px;
      height: 18px;
      float: left;
      display: flex;
      flex-direction: column;
    }
    .messageNoteCheckout {
      display: flex;
    }
  }

  .toppings {
    display: flex;
    flex-direction: column;
    margin-bottom: 13px;
    border-radius: 8px;
    padding: 12px 8px 12px 8px;
    margin: 0px 60px 13px 104px;
    .topping-item {
      display: flex;
      justify-content: space-between;

      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #959595;
      .text-line-clamp-1 {
        flex: 6;
        padding-right: 24px;
      }
      .quantity {
        flex: 2;
      }

      .price-value {
        margin-right: 24px;
        flex: 1;
        .topping-price-col {
          display: flex;
          flex-direction: column;
          .topping-original-price {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            text-decoration-line: line-through;
            color: #959595;
          }
        }
      }
    }
    .topping-item:not(:first-child) {
      margin-top: 12px;
    }
  }

  .box-combo {
    width: calc(100% - 60px);
    border-radius: 12px;
    padding: 12px;
    margin: 0 60px 12px 0;
    padding-top: 0;
    .box-combo-item {
      font-style: normal;
      margin-bottom: 12px;
      .product-name {
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #282828;
        margin-bottom: 12px;
      }
      .product-option,
      .product-topping {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #959595;
        margin-bottom: 8px;
        .topping-item {
          width: 100%;
          display: flex;
          justify-content: space-between;
          .text-line-clamp-1 {
            flex: 6;
          }
          .quantity {
            flex: 2;
          }
          .name {
            min-width: 40%;
          }
          .price-value {
            flex: 1;
            margin-right: 12px;
          }
        }
      }
    }
  }
}

.box-drawer-pos-theme2 {
  * {
    font-family: inherit;
  }
  .ant-drawer-header {
    background-color: #db4d29;
    padding: 20px 16px;
    .ant-drawer-header-title {
      gap: 15px;
      button svg {
        path {
          stroke: #fff;
        }
      }
      .ant-drawer-title {
        color: #fff;
        font-weight: 700;
        font-size: 20px;
      }
    }
  }
}

/* Mobile */
@media (max-width: 740px) {
  .checkout-order-item-theme-2 {
    width: 100%;
    display: flex;
    flex-direction: row;
    padding-bottom: 16px;
    padding-left: 12px;
    padding-right: 12px;
    .order-item-image {
      cursor: pointer;
      width: 106px;
      height: 80px;
      margin-right: 12px;
      margin-bottom: 12px;
      .fnb-display-image .display-image {
        border-radius: 12px;
      }
      .fnb-display-image .flash-sale-border {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-width: 2px;
        border-bottom-width: 13px;
        border-radius: 12px;
      }
      .fnb-display-image .flash-sale {
        width: 80%;
        bottom: -2.5px;
        left: 3px;
      }
    }
    .order-item {
      display: flex;
      flex-direction: column;
      width: 100%;
      .order-item-info {
        width: unset;
        display: flex;
        justify-content: space-between;
        width: 100%;
        .name {
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          color: #282828;
          margin-bottom: 4px;
        }
        .delete-icon {
          width: 24px;
          min-width: 24px;
          height: 24px;
        }
      }
      .discount {
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        color: #ffffff;
        padding: 4px 8px;
        background-color: #ffa318;
        border-radius: 20px;
        width: fit-content;
        margin-bottom: 16px;
      }
      .options {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #959595;
        margin-bottom: 8px;
      }

      .order-item-price {
        width: 20%;
        display: flex;
        flex-direction: column;

        font-style: normal;
        .price-after-discount {
          font-weight: 600;
          font-size: 14px;
          line-height: 24px;
          color: #282828;
        }
        .price {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: #959595;
          text-decoration: line-through;
        }
      }

      .toppings {
        display: flex;
        flex-direction: column;
        margin-bottom: 13px;
        margin: unset;
        .topping-item {
          width: 100%;
          display: flex;
          justify-content: space-between;
          margin-left: unset;

          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: #959595;
          .name {
            min-width: calc(40% + 12px);
          }
          .price-value {
            margin-right: 12px;
          }
        }
      }

      .box-combo {
        width: calc(100% - 24px);
        border-radius: 12px;
        padding: 12px;
        margin: unset;
        .box-combo-item {
          font-style: normal;
          margin-bottom: 12px;
          .product-name {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #282828;
            margin-bottom: 12px;
          }
          .product-option,
          .product-topping {
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: #959595;
            margin-bottom: 8px;
            .topping-item {
              width: 100%;
              display: flex;
              justify-content: space-between;
              .name {
                min-width: 40%;
              }
              .price-value {
                margin-right: 0px;
              }
            }
          }
        }
      }

      .order-item-quantity-total {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin: 16px 0;
        .order-item-quantity {
          width: unset;
          display: flex;
          .quantity {
            min-width: 50px;
            text-align: center;
            user-select: none;
          }
        }

        .order-item-total {
          width: unset;
          .total-amount {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 24px;
            color: #282828;
            user-select: none;
          }
        }
      }
    }
    .cartMessageCheckout {
      margin: unset;
    }
  }
}
.modal-product-cart-detail {
  height: 80vh;
  overflow: hidden;
  .ant-modal-close-icon {
    font-size: 29.34px;
  }
  .ant-modal-close {
    color: rgb(0 0 0);
    width: 29px;
    height: 29px;
    top: 24px;
  }
  .ant-modal-content {
    background-color: transparent;
    height: 100%;
    padding-top: 77px;
    overflow: hidden;

    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: transparent;
      margin-top: 15px;
      margin-bottom: 15px;
    }

    &::-webkit-scrollbar {
      width: 4px;
      height: 0;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #e0e0e0;
      min-height: 30px;
    }
  }
}

@media (max-width: 500px) {
  .drawer-product-cart-detail {
    border-radius: 30px 30px 0px 0px;
  }
  ::-webkit-scrollbar {
    display: none;
  }
}
.modal-product-cart-detail {
  .ant-modal-body {
    height: 100%;
  }
}
.modal-product-cart-detail {
  height: calc(100vh - 64px) !important;
}
