.product-list-header-theme2-customize,
.product-list-header-theme2 {
  width: 100%;
  height: 322px;
  background: #fef7ed;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  -moz-background-size: 100% 100%;
  -o-background-size: 100% 100%;
  -webkit-background-size: 100% 100%;
  background-size: cover !important;
  background-repeat: no-repeat;
  .title {
    color: #282828;
    
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 44px;
    margin: auto;
    text-align: center;
    cursor: pointer;
    overflow-wrap: break-word;
    user-select: none;
    width: 1200px;
    max-width: 100%;
  }
}

.product-list-header-theme2-customize:active {
  border: 3px solid blue;
}

@media screen and (max-width: 639px) {
  .product-list-header-theme2 {
  }
}
