$text_size1: 12px;
$padding_size: 20px;

.checkout-theme2-notify-dialog {
  .confirmation-modal-theme2 .ant-modal-content .ant-modal-body {
    padding: 24px 24px 24px 24px;
  }

  .btn-got-it {
    width: 210px !important;
  }

  .warning-schedule-time {
    display: flex;
    flex-direction: column;
  }
}

.discount-code-dialog {
  .customize-dialog-modal {
    * {
      font-family: inherit;
    }

    top: 60px !important;
    width: 532px !important;

    .ant-modal-body {
      padding: 24px 0px !important;
    }
  }
}

.summary-container {
  background: #f9f9f9;
  border-width: 0.5px 0.5px 0.5px 0.5px;
  border-style: solid;
  border-color: #e6e6e6;
  padding: 4px 16px;
  border-radius: 12px;
  padding-bottom: 20px;
}

.check_out_theme2_container {
  * {
    font-family: inherit;
  }

  -moz-background-size: 100% 100%;
  -o-background-size: 100% 100%;
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  padding: 20px 0;
  padding-bottom: 50px;

  .check_out_product {
    width: calc(70% - 36px);
    margin-right: 36px;

    .cart-container {
      margin-top: 16px;
    }

    .product_summary {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #f9f9f9;
      padding: 8px 16px;
      border-width: 0 1px 1px 1px;
      border-style: solid;
      border-color: #e6e6e6;
      border-radius: 12px 12px 0px 0px;

      .total {
        display: flex;

        .shoppingCart {
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 32px;
          color: #db4d29;
          margin-right: 5px;
          text-transform: uppercase;
        }

        .quantity {
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 32px;
          color: #282828;
          text-transform: uppercase;

          .total-quantity {
            padding-right: 5px;
          }
        }
      }

      .add {
        display: flex;
        align-items: center;

        .add_icon {
          cursor: pointer;
          display: flex;
        }

        .add_button {
          margin-left: 14px;

          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          color: #db4d29;
          cursor: pointer;
          text-transform: uppercase;
        }
      }
    }

    .header-container {
      background: #ffffff;
      border-width: 0 1px 1px 1px;
      border-style: solid;
      border-color: #e6e6e6;
      border-radius: 0 0 12px 12px;
    }

    .product_title {
      display: flex;
      justify-content: space-between;
      align-items: center;

      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: #282828;
      height: 48px;
      margin-bottom: 12px;
      padding-left: 16px;
      padding-right: 16px;

      .title-wrapper {
        border-bottom-width: 0.5px;
        border-bottom-style: solid;
        border-bottom-color: #dfe2e6;
        display: flex;
        flex-direction: row;
        width: 100%;
        padding-bottom: 8px;
        padding-top: 24px;
      }
    }

    .product_title.product-title-mobile {
      display: none;
    }

    .product_detail {
      overflow-y: auto;

      .noProductInCart {
        margin-top: 127px;
        text-align: center;
      }

      .noProductInCartText {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        margin-top: 24px;
      }

      .checkout-order-item-theme-2 {
        .order-item {
          .order-item-detail {
            .order-item-infomation {
              flex: 1;

              .order-item-info {
                flex: 1.4;
              }

              .order-item-price,
              .order-item-quantity,
              .order-item-total {
                flex: 1;

                .total-amount {
                  margin-left: 4px;
                }
              }
            }

            .toppings {
              margin-left: 0px;
            }
          }
        }
      }
    }
  }

  .check_out_shipping {
    width: 30%;

    ///Apply new UI
    .note {
      margin-bottom: 24px;

      .order-note-icon {
        position: absolute;
        z-index: 1;
        margin-top: 16px;
        margin-left: 16px;
      }

      .note-input {
        height: 56px;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        padding: 16px 16px 16px 50px;
        border-radius: 12px;
        border: 1px solid #e6e6e6;
        border-radius: 12px;
        text-align: justify;
      }
    }

    .available-point {
      padding: 16px;
      gap: 12px;
      width: 100%;
      background: #ffffff;
      border: 1px solid #e6e6e6;
      border-radius: 12px;
      margin-bottom: 24px;
    }

    .mb-24 {
      margin-bottom: 24px;
    }
  }

  .button-create-order-and-payment {
    * {
      font-family: inherit;
    }

    .pay-button {
      width: 100%;
      height: 48px;
      background: #db4d29;
      border-radius: 60px;

      span {
        color: #ffffff;

        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
      }
    }

    .pay-button:disabled {
      background: #ecb9ac;
    }

    .add-item-button {
      width: 100%;
      height: 48px;
      border-radius: 12px;
      color: #db4d29;
      border-color: #db4d29;

      span {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
      }
    }

    .pay-button:disabled {
      background: #ecb9ac;
    }
  }

  .receive-point-text {
    font-style: normal;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    padding: 6px 5px;
    display: flex;

    .total {
      margin-top: 0;
    }

    .point-logo {
      vertical-align: middle;
      margin-right: 12px;
    }

    .earn-points {
      color: #ffa318;
      font-weight: 500;
      line-height: 20px;
      font-size: 14px;
    }
  }
}

@media (max-width: 1336px) {
  .check_out_theme2_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 auto;
    padding: 16px 24px;

    .check_out_product {
      width: 100%;

      .product_detail {
        height: auto;
        min-height: auto;
        margin-bottom: 36px;
      }
    }

    .check_out_shipping {
      width: 100%;
    }
  }
}

@media screen and (max-width: 639px) {
  .check_out_theme2_container {
    display: flex;
    flex-flow: column nowrap;
    padding-left: 16px !important;
    padding-right: 16px !important;

    .check_out_product {
      flex: 1;
      width: 100%;
      margin-right: unset;

      .product_summary {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 29px;

        .total {
          font-size: 12px;
        }
      }

      .product_title.product-title-web {
        display: none;
      }

      .product_title.product-title-mobile {
        display: flex;
        border-bottom-width: 0.5px;
        border-bottom-style: solid;
        border-bottom-color: #dfe2e6;
        padding-top: 12px;
        padding-bottom: 8px;
      }

      .product_detail {
        height: unset;
        min-height: unset;
        overflow-y: unset;
      }
    }

    .check_out_shipping {
      flex: 1;
      display: flex;
      flex-flow: column nowrap;
      width: 100%;
      margin-left: 0;
      margin-top: 10px;
    }
  }
}

@media (max-width: 575px) {
  .check_out_theme2_container {
    .check_out_product {
      .product_detail {
        .noProductInCart {
          margin-bottom: 101.75px;
        }
      }

      .product_summary {
        margin-bottom: 0;
        .total {
          .shoppingCart {
            font-size: 14px;
          }

          .quantity {
            font-size: 14px;
          }
        }

        .add {
          .add_button {
            margin-left: 6px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 835px) {
  .check_out_product {
    .product_summary {
      .total {
        .shoppingCart {
          font-size: 20px;
        }

        .quantity {
          font-size: 20px;
        }
      }
    }
  }
}
