.login-page-wrapper {
  padding-top: 40px;
  padding-bottom: 54px;

  .login-page-container-theme2 {
    width: auto;
    height: auto;
    padding: 122px 16px 121px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;

    .login-page-title {
      font-style: normal;
      font-weight: 700;
      font-size: 52px;
      line-height: 63px;
      text-align: center;
      text-transform: uppercase;
    }

    .login-page-input {
      margin-bottom: 40px;
      padding: 12px 16px;
      border-radius: 12px;
      border: 1px solid #6d6d6d;
      background-color: rgba(255, 255, 255, 0.7);
      width: 40%;

      &:hover {
        box-shadow: initial !important;
        border: 1px solid #6d6d6d !important;
      }
    }

    .login-page-btn {
      height: 60px;
      color: #fff;
      border-radius: 12px;
      padding: 10px;
      border: none;
      outline: none;
      width: 40%;
      margin-top: 30px;

      &:hover {
        cursor: pointer;
        opacity: 0.8;
      }

      span {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
}

/*Login page*/
.login-title {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 21px;
  color: #282828;
  margin-top: 21px;
}

.login-page-container-otp {
  $margin_top: 30px;
  $padding_login: 20px;
  $number_width: 60px;
  $number_height: 60px;
  .login_page {
    margin: auto;
    width: 450px;
    height: fit-content;
    padding-top: 10px;
    padding-bottom: 10px;
    background: #ffffff;
    border-radius: 20px;
    align-items: center;
    text-align: center;
    padding: 133px;
    .login_title {
      padding-left: $padding_login;
      padding-right: $padding_login;

      font-style: normal;
      font-weight: 600;
      font-size: 42px;
      line-height: 63px;
      align-items: center;
      text-align: center;
      color: #282828;
    }
    .login_detail {
      margin-top: $margin_top;
      padding-left: $padding_login;
      padding-right: $padding_login;

      .login_phone_icon {
        position: relative;
        padding-left: 10px;
        padding-right: 5px;
        top: 35px;
        z-index: 2;
        float: left;
      }

      .login_country {
        position: relative;

        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        color: #000000;
        z-index: 2;
        .login_country_popup {
          width: 600px;
        }
        float: left;
        top: 32px;
      }
      .login_phone {
        padding-inline-start: 100px;

        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: #000000;
        float: left;
      }
    }
    .login_phone_error {
      padding-left: $padding_login;

      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 23px;
      float: left;
      color: #ff0000;
    }
    .login_button_box {
      margin-top: $margin_top;
      padding-left: $padding_login;
      padding-right: $padding_login;

      .login_button {
        width: 100%;
        background: #db4d29;
        opacity: 1;
        border-radius: 12px;
        color: white;
      }
    }

    .login_otp_phone {
      margin: auto;
      text-align: center;

      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 23px;
      align-items: center;
      text-align: center;
    }

    .login_otp_title1 {
      margin: auto;
      text-align: center;

      font-style: normal;
      font-weight: 700;
      font-size: 40px;
      line-height: 44px;
      align-items: center;
      text-align: center;
      color: #282828;
    }
    .login_otp_title2 {
      margin: auto;
      text-align: center;

      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      align-items: center;
      color: #282828;
    }
    .login_otp_title3 {
      margin: auto;
      text-align: center;

      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 23px;
    }
    .login_otp_resend_title {
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 23px;
      align-items: center;
      text-align: center;
      color: #000000;
    }
    .login_otp_resend1 {
      display: none;
      cursor: pointer;
      margin: auto;
      text-align: center;
      color: rgba(94, 141, 236, 1);
    }
    .login_otp_resend2 {
      cursor: pointer;
      margin: auto;
      text-align: center;
      color: rgba(190, 190, 190, 1);
    }
    .login_otp_numbers {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      margin-top: 20px;
      .login_otp_number1 {
        height: $number_height;
        width: $number_width;
        padding-left: 5px;
        padding-right: 5px;
        .login_otp_n1 {
          text-align: center;
          background: #fef7ed;
          border: 1px solid #6d6d6d;
          border-radius: 12px;
          width: 100%;
          height: 100%;

          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 23px;
          align-items: center;
          text-align: center;
        }
      }
      .login_otp_number2 {
        height: $number_height;
        width: $number_width;
        padding-left: 5px;
        padding-right: 5px;
        .login_otp_n2 {
          text-align: center;
          text-align: center;
          background: #fef7ed;
          border: 1px solid #6d6d6d;
          border-radius: 12px;
          width: 100%;
          height: 100%;

          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 23px;
          align-items: center;
          text-align: center;
        }
      }
      .login_otp_number3 {
        height: $number_height;
        width: $number_width;
        padding-left: 5px;
        padding-right: 5px;
        .login_otp_n3 {
          text-align: center;
          text-align: center;
          background: #fef7ed;
          border: 1px solid #6d6d6d;
          border-radius: 12px;
          width: 100%;
          height: 100%;

          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 23px;
          align-items: center;
          text-align: center;
        }
      }
      .login_otp_number4 {
        height: $number_height;
        width: $number_width;
        padding-left: 5px;
        padding-right: 5px;
        .login_otp_n4 {
          text-align: center;
          text-align: center;
          background: #fef7ed;
          border: 1px solid #6d6d6d;
          border-radius: 12px;
          width: 100%;
          height: 100%;

          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 23px;
          align-items: center;
          text-align: center;
        }
      }
      .login_otp_number5 {
        height: $number_height;
        width: $number_width;
        padding-left: 5px;
        padding-right: 5px;
        .login_otp_n5 {
          text-align: center;
          text-align: center;
          background: #fef7ed;
          border: 1px solid #6d6d6d;
          border-radius: 12px;
          width: 100%;
          height: 100%;

          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 23px;
          align-items: center;
          text-align: center;
        }
      }
      .login_otp_number6 {
        height: $number_height;
        width: $number_width;
        padding-left: 5px;
        padding-right: 5px;
        .login_otp_n6 {
          text-align: center;
          text-align: center;
          background: #fef7ed;
          border: 1px solid #6d6d6d;
          border-radius: 12px;
          width: 100%;
          height: 100%;

          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 23px;
          align-items: center;
          text-align: center;
        }
      }
    }
    .login_otp_error {
      color: red;

      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 23px;
      align-items: center;
      text-align: center;
      margin-top: 20px;
    }

    .login_button_resend_box {
      margin: auto;
      .login_button_resend {
        margin-top: 10px;
        width: 100%;
        background: #db4d29;
        border-radius: 12px;

        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        align-items: center;
        text-align: center;
        color: #fef7ed;
      }
    }
  }
  .login-page-container-theme2 {
    .login_detail {
      width: 40%;

      .login_phone_icon {
        position: relative;
        padding-left: 18px;
        padding-right: 5px;
        top: 47px;
        z-index: 2;
        float: left;
      }

      .login_country {
        position: relative;

        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        color: #000000;
        z-index: 2;
        .login_country_popup {
          width: 600px;
        }
        float: left;
        top: 44px;
      }
      .login_phone {
        padding-inline-start: 100px;

        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: #000000;
        float: left;
        border-radius: 20px;
        display: flex;
        align-items: center;
        height: 56px;
        .ant-input {
          margin-left: 15px;
        }
      }
    }
  }
  @media screen and (max-width: 639px) {
    .login-page-container-theme2 {
      .login-page-theme2 {
        position: 0;
        margin: auto;
        width: 100%;
        right: 0;
        top: 0;
      }
    }
  }
}

.change-login-mode {
  margin-top: 10px;
}
