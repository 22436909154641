.edit-order-combo-theme-pho-viet-section {
  width: 100%;
  height: 100%;

  #editOrderComboDetailBody {
    height: 100%;
    .edit-order-theme-pho-viet-section-group {
      height: 100%;
      display: flex;
      max-width: 100%;
      background-attachment: initial;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      margin: auto;
      padding: unset;
      gap: 37.59px;

      .edit-order-theme-pho-viet-section-left {
        width: 41%;
        height: 100%;
        padding-top: 16px;
        position: relative;

        .product-detail-image-swiper {
          width: 100%;
          height: 100%;
          border: 1px solid #e6e6e6;
          border-radius: 20px;
          aspect-ratio: 1/1;

          .swiper-button-prev,
          .swiper-button-next {
            color: #51526c;
          }

          .swiper-wrapper {
            .swiper-slide {
              display: flex;
              align-items: center;
              justify-content: center;
              .image-zoom-in {
                width: 50%;
                height: 50%;
              }
            }
          }
        }

        .slider-product-detail {
          width: 100%;
          height: 570px;
          border: 1px solid #e6e6e6;
          border-radius: 20px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          .product-detail-slider-image {
            width: 100%;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            .image-product-detail {
              width: 386px;
              height: 379px;
            }
          }
          .slick-arrow {
            width: 18.75px;
            height: 37.5px;
            z-index: 9999;
          }
          .slick-next {
            right: 4%;
          }
          .slick-prev {
            left: 4%;
          }
        }

        .swiper-small-image {
          .swiper-wrapper {
            .swiper-slide {
              width: 130px;
              height: 130px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 20px;
              border: 1px solid #e6e6e6;

              .image-zoom-out {
                width: 113px;
                height: 113px;
                border: 1px solid #e6e6e6;
                border-radius: 20px;
              }
            }

            .swiper-slide-thumb-active {
              border: 1px solid #db4d29;
            }
          }
        }

        .discount-edit-order {
          position: absolute;
          max-width: max-content;
          max-height: max-content;
          font-weight: 500;
          background: #ffa318;
          border-radius: 50px;
          color: #fff;
          min-width: 64px;
          text-align: center;
          min-height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 9;
          margin: 16px 0px 0px 16px;

          .discount-text {
            margin: 6.02px 12px;
          }
        }

        .mySwiper {
          width: 100%;
        }

        .ant-image,
        .ant-image-error {
          width: 100%;
          height: 100%;
          aspect-ratio: 1/1;
          border-radius: 20px;

          .product-image {
            width: 100%;
            height: 100%;
            aspect-ratio: 1/1;
            border-radius: 50px;
            padding: 16px;
            background-color: white;
          }
        }
        .border-image-thumbnail {
          border: 1px solid #e6e6e6;
          border-radius: 20px;
          position: relative;
        }
        .image-sub-group {
          margin-top: 24px;
          .ant-image {
            height: 130px;
            .image-sub {
              padding: 8px;
              width: 130px;
              height: 130px;
              border-radius: 20px;
              border: 1px solid #db4d29;
              background-color: white;
            }
          }
        }
      }

      .edit-order-theme-pho-viet-section-right {
        width: 59%;
        height: 100%;
        overflow: auto;
        .select-edit-order {
          margin-top: 24px;
        }
        ::-webkit-scrollbar {
          width: 5px;
        }

        ::-webkit-scrollbar-track {
          background: transparent;
        }

        ::-webkit-scrollbar-thumb {
          background: #c2d4e5;
          border-radius: 8px;
        }
        .product-name {
          width: 100%;
          font-size: 24px;
          font-weight: 700;
          margin-bottom: unset;
          line-height: 32px;
        }

        .group-star-rate {
          display: flex;
          align-items: center;
          li.ant-rate-star.ant-rate-star-full {
            margin-inline-end: 2px;
          }
          .total-review {
            margin-left: 12px;
            line-height: 31.42px;
            text-transform: uppercase;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            margin-top: 10px;
            color: #000000;
          }
          .ant-rate {
            font-size: 32px;
            svg {
              width: 32px;
              height: 32px;
            }
          }
        }

        .description-product {
          font-size: 14px;
          margin-top: 25.5px;
          text-align: justify;
          color: #959595;
        }

        .product-price {
          margin-top: 20px;
          display: flex;
          align-items: center;
          .product-price-left {
            width: max-content;
            white-space: nowrap;
            .product-price-discount {
              margin-left: 12px;
              font-size: 20px;
              font-weight: 700;
              color: #ffa318;
              line-height: 28px;
            }
            .product-price-original {
              font-size: 18px;
              font-weight: 500;
              color: #959595;
              text-decoration: line-through;
              margin-right: 12px;
            }
          }
          .product-price-right {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            width: 100%;
            padding: 0 10px;
            .product-price-btn-increase,
            .product-price-btn-decrease {
              width: 40px;
              height: 40px;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              font-size: 20px;
              font-weight: bold;
              cursor: pointer;
              align-items: center;
            }

            .active-click {
              cursor: pointer;
            }
            .prevent-click {
              cursor: no-drop !important;
            }

            .product-price-btn-decrease {
              background: #ebebeb;
              color: #51526c;
              svg > rect {
                fill: #ebebeb;
              }
            }
            .product-price-btn-increase {
              background: #db4d29;
              color: #fff;
            }

            .product-price-quantity {
              margin: 0 46.5px;
              font-style: normal;
              font-weight: 700;
              font-size: 20px;
              line-height: 28px;
            }
          }
        }

        .product-description {
          color: #959595;
          font-size: 14px;
          margin-top: 20px;
          text-align: justify;

          .ant-typography {
            .ant-typography-expand {
              color: #db4d29;
              font-size: 14px;
            }
          }
        }

        .product-title-box {
          display: flex;
          height: 100%;
          align-items: center;

          div.product-logo {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            border-radius: 24px;
            margin-right: 14px;
            img {
              width: 72px;
              height: 72px;
              border-radius: 8px;
            }
          }

          div.product-information {
            display: grid;
            span.product-label {
              font-style: normal;
              font-weight: 700;
              font-size: 20px;
              line-height: 28px;
              display: flex;
              align-items: center;
            }
            span.product-size-name {
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 24px;
              display: flex;
              align-items: center;
              color: #959595;
            }
          }
        }

        .group-multiple-price {
          margin-top: 24px;
          width: 100%;
          .group-collapse {
            border: unset;
            border-radius: 8px;

            .ant-collapse-item {
              border: unset;
              .ant-collapse-header {
                background: #f9f1df;
                font-size: 20px;
                font-weight: bold;
                color: #000000;
                border-radius: 8px;
                line-height: 28px;
                .ant-collapse-header-text {
                  text-transform: uppercase;
                }
              }
              .ant-collapse-content {
                border: unset;
                border-radius: 8px;
                .ant-collapse-content-box {
                  padding: unset;

                  .group-multiple-price-content {
                    display: grid;
                    align-items: center;

                    .group-multiple-price-item {
                      height: 56px;
                      width: 100%;
                      display: inline-flex;
                      align-items: center;
                      justify-content: space-between;
                      padding: unset;
                      border-bottom: 1px solid #e6e6e6;

                      .price-name {
                        height: 100%;
                        display: grid;
                        align-items: center;
                        padding: 8px 0;
                        margin-left: 16px;

                        .name {
                          font-size: 16px;
                          font-weight: 500;
                        }

                        .price-name-value {
                          font-size: 14px;
                          font-weight: 400;
                        }
                      }

                      .checkbox-price {
                        margin-right: 16px;
                      }

                      .price-name-value-radio {
                        width: 24px;
                        height: 24px;
                      }
                    }
                  }

                  .group-multiple-price-content *:last-child {
                    border: unset;
                  }
                }
              }
              .ant-collapse-expand-icon {
                margin: auto;
              }
            }
          }
        }

        .group-product-option {
          width: 100%;
          margin-top: 12px;

          .group-collapse {
            border: unset;
            border-radius: 8px;

            .ant-collapse-item {
              border: unset;
              .ant-collapse-header {
                background: #f9f9f9;
                font-size: 16px;
                font-weight: bold;
                color: #000000;
                border-radius: 8px;
                .ant-collapse-header-text {
                  text-transform: uppercase;
                }
              }
              .ant-collapse-content {
                border: unset;
                border-radius: 8px;
                .ant-collapse-content-box {
                  padding: unset;
                  margin-top: 0 !important;

                  .group-product-option-content {
                    display: grid;
                    align-items: center;
                    padding: 0 16px;
                    justify-content: stretch;

                    .group-product-option-item {
                      height: fit-content;
                      width: 100%;
                      display: inline-flex;
                      align-items: center;
                      padding: unset;
                      border-bottom: 1px solid #e6e6e6;
                      justify-content: space-between;

                      .option-name {
                        font-size: 16px;
                        font-weight: 600;
                      }

                      .option-level-value {
                        width: fit-content;
                        display: flex;
                        align-items: center;
                        flex-wrap: wrap;
                        justify-content: flex-end;
                        margin: 8px;
                        gap: 10px;

                        .option-level-value-item {
                          height: 32px;
                          border-radius: 7px;
                          background: #fff;
                          color: #000;
                          min-width: 82px;
                          font-size: 12px;
                          display: flex;
                          align-items: center;
                          justify-content: center;
                          font-weight: 500;
                          border: unset;
                          white-space: nowrap;

                          .ant-radio-button-checked {
                            background-color: #db4d29;
                            border-radius: 7.5px;
                            border: 1px solid #db4d29;
                          }

                          .container-radio-option {
                            display: flex;
                            height: fit-content;
                            margin-left: 0;
                            padding-left: 0;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 24px;
                          }

                          .dot-select-product-option {
                            color: #ffffff;
                            font-size: 35px;
                            margin: 0 7.33px 0 0;
                            position: relative;
                            top: -10px;
                          }
                          .ant-radio-button:last-child {
                            border: unset;
                          }
                        }

                        .ant-radio-button-wrapper-checked {
                          color: #fff;
                          font-size: 14px;
                        }

                        .d-flex-prefix {
                          display: flex;
                        }

                        .option-level-value-item:not(:first-child)::before {
                          background-color: unset !important;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .group-product-topping {
          margin-top: 12px;
          width: 100%;

          .group-collapse {
            border: unset;
            border-radius: 8px;

            .ant-collapse-item {
              border: unset;
              .ant-collapse-header {
                background: #f9f1df;
                font-size: 16px;
                font-weight: bold;
                color: #000000;
                border-radius: 8px;
                .ant-collapse-header-text {
                  text-transform: uppercase;
                }
              }
              .ant-collapse-content {
                border: unset;
                border-radius: 8px;
                .ant-collapse-content-box {
                  padding: unset !important;

                  .group-product-topping-content {
                    display: grid;
                    align-items: center;

                    .group-product-topping-item {
                      height: 56px;
                      width: 100%;
                      display: inline-flex;
                      align-items: center;
                      justify-content: space-between;
                      padding: unset;
                      border-bottom: 1px solid #e6e6e6;
                      gap: 10px;

                      .topping-name {
                        height: 100%;
                        display: grid;
                        align-items: center;
                        padding: 8px 0;
                        margin-left: 16px;

                        .name {
                          font-size: 16px;
                          font-weight: 600;
                        }

                        .topping-price-value {
                          font-size: 14px;
                          font-weight: 400;
                        }
                      }

                      .topping-change-quantity {
                        display: inline-flex;
                        align-items: center;
                        justify-content: end;
                        margin-right: 16px;

                        .topping-quantity-btn-increase,
                        .topping-quantity-btn-decrease {
                          width: 24px;
                          height: 24px;
                          border-radius: 50%;
                          display: flex;
                          align-items: self-end;
                          justify-content: center;
                          font-weight: bold;
                          cursor: pointer;
                          align-items: center;
                        }

                        .topping-quantity-btn-decrease {
                          background: #ebebeb;
                          color: #51526c;
                          svg > rect {
                            fill: #ebebeb;
                          }
                        }

                        .topping-quantity-btn-increase {
                          background: #db4d29;
                          color: #fff;
                        }

                        .topping-quantity-value {
                          margin: 0 20px;
                          font-style: normal;
                          font-weight: 500;
                          font-size: 16px;
                          line-height: 24px;
                        }
                      }
                    }
                  }

                  .group-product-topping-content *:last-child {
                    border: unset;
                  }
                }
              }
            }
          }
        }

        .input-note-edit-order {
          position: relative;
          padding-bottom: 5px;
          .icon-note-product-detail {
            position: absolute;
            bottom: 25%;
            left: 2.5%;
          }
          .product-note {
            width: 100%;
            height: 60px;
            color: #282828;
            border-radius: 8px;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            padding: 20px 16px 20px 50px;
            border: 1px solid #e6e6e6;
          }
          .product-note:focus {
            border: 1px solid #e6e6e6;
          }
          .note-icon {
            position: relative;
            top: 50px;
            left: 10px;
            margin-left: 5px;
            z-index: 998;
          }
        }

        .btn-add-to-cart {
          width: 100%;
          height: 60px;
          background: #db4d29;
          border-radius: 12px;
          color: #ffffff;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: unset;
          font-size: 20px;
          font-weight: 500;

          .btn-add-to-cart-text {
            display: flex;
            align-items: center;
            margin-right: 20px;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 28px;

            .icon-check-out {
              margin-left: 10px;
            }
          }

          .btn-add-to-cart-price-value {
            margin-left: 20px;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 28px;
          }
        }

        .btn-add-to-cart:hover {
          cursor: pointer;
          border: 1px solid #db4d29;
          font-weight: 500;
        }

        .margin-top {
          margin-top: 12px !important;
        }

        .group-product-combo {
          width: 100%;

          .ant-collapse {
            background-color: transparent !important;
          }

          .ant-collapse-expand-icon {
            margin: auto;
          }
          .group-collapse {
            border: unset;
            border-radius: 8px;

            .product-name-banner {
              border: unset;

              .ant-collapse-header {
                background: #ffffff;
                font-size: 20px;
                font-weight: 700;
                border: 1px solid #e6e6e6;
                border-radius: 8px;
              }

              .ant-collapse-content {
                border: unset;
                border-radius: 8px;
                .ant-collapse-content-box {
                  padding: 0 !important;
                  margin-top: 12px;
                  .group-product-option,
                  .group-product-topping {
                    margin-top: unset;

                    .group-collapse {
                      .ant-collapse-item {
                        .ant-collapse-header {
                          background: #f9f9f9;
                          color: #959595;
                          .ant-collapse-expand-icon {
                            display: none;
                          }
                        }

                        .ant-collapse-content {
                          border-radius: 8px;
                          .ant-collapse-content-box {
                            padding-top: unset !important;
                          }
                        }
                      }
                    }
                  }

                  .group-product-topping {
                    margin-top: 12px;
                  }
                }
              }
            }
          }
        }
        .overflow-item-detail {
          overflow: auto;
          height: calc(100% - 85px);
        }
        .total-cart {
          margin-top: 24px;
          height: 60px;
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .edit-order-combo-theme-pho-viet-section {
    width: 100%;
    #editOrderComboDetailBody {
      .edit-order-theme-pho-viet-section-group {
        flex-direction: column;
        min-width: 100px !important;
        gap: 21.41px;
      }
      .edit-order-theme-pho-viet-section-right {
        width: 100% !important;
      }
      .edit-order-theme-pho-viet-section-left {
        width: 100% !important;
        padding: 0 !important;
      }
      .btn-add-cancel {
        background: #fff !important;
        border: 1px solid #e2e2e2 !important;
        color: #000 !important;
        justify-content: center !important;
      }
      .btn-add-to-cart {
        font-size: 14px !important;
        height: 48px !important;
        margin-top: 16px !important;
        justify-content: space-around !important;

        .btn-add-to-cart-text {
          margin-right: 10px !important;
          font-weight: 500 !important;
          font-size: 16px !important;

          .icon-check-out {
            margin-left: 5px !important;
            height: 20px !important;
            width: 20px !important;
          }
        }

        .btn-add-to-cart-price-value {
          font-weight: 500;
          margin-left: 10px !important;
          font-weight: 500 !important;
          font-size: 16px !important;
        }
      }

      .total-cart {
        position: absolute;
        padding: 0 16px 16px 16px;
        z-index: 99999;
        background: #fff;
        bottom: 0;
        left: 0;
        right: 0;
        height: auto !important;
      }

      .product-detail-image-swiper {
        width: 100%;
        height: 100%;
        border: 1px solid #e6e6e6;
        border-radius: 20px;
        aspect-ratio: 1/1;

        .swiper-button-prev,
        .swiper-button-next {
          color: #51526c;
        }

        .swiper-wrapper {
          .swiper-slide {
            display: flex;
            align-items: center;
            justify-content: center;
            .image-zoom-in {
              width: 50%;
              height: 50%;
            }
          }
        }
      }

      .slider-product-detail {
        width: 100%;
        height: 570px;
        border: 1px solid #e6e6e6;
        border-radius: 20px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        .product-detail-slider-image {
          width: 100%;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          .image-product-detail {
            width: 386px;
            height: 379px;
          }
        }
        .slick-arrow {
          width: 18.75px;
          height: 37.5px;
          z-index: 9999;
        }
        .slick-next {
          right: 4%;
        }
        .slick-prev {
          left: 4%;
        }
      }

      .swiper-small-image {
        .swiper-wrapper {
          .swiper-slide {
            width: 130px;
            height: 130px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 20px;
            border: 1px solid #e6e6e6;

            .image-zoom-out {
              width: 113px;
              height: 113px;
              border: 1px solid #e6e6e6;
              border-radius: 20px;
            }
          }

          .swiper-slide-thumb-active {
            border: 1px solid #db4d29;
          }
        }
      }

      .discount-edit-order {
        position: absolute;
        max-width: max-content;
        max-height: max-content;
        font-weight: 500;
        background: #ffa318;
        border-radius: 45.1351px;
        color: #fff;
        min-width: 50px;
        text-align: center;
        min-height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 9;

        .discount-text {
          margin: 6.73px 13.45px;
        }
      }

      .mySwiper {
        width: 100%;
      }

      .ant-image,
      .ant-image-error {
        width: 100%;
        height: 100%;
        aspect-ratio: 1/1;
        border-radius: 20px;

        .product-image {
          width: 100%;
          height: 100%;
          aspect-ratio: 1/1;
          border-radius: 20px;
        }
      }

      .group-star-rate {
        display: flex;
        align-items: center;
        li.ant-rate-star.ant-rate-star-full {
          margin-inline-end: 2px;
        }
        .total-review {
          margin-left: 12px;
          line-height: 31.42px;
          text-transform: uppercase;
          font-style: normal;
          font-weight: 500;
          font-size: 12.8353px;
        }

        .ant-rate {
          font-size: 16px;
          svg {
            width: 16px;
            height: 16px;
          }
        }
      }

      .btn-add-cancel {
        background: #ffffff;
        border: 1px solid #e2e2e2;
        color: #000;
        justify-content: center;
        width: 100%;
        height: 60px;
        margin-top: 16px;
        border-radius: 12px;
        display: flex;
        align-items: center;
        padding: unset;
        font-size: 16px;
        font-weight: 500;
        height: 48px !important;
      }
      .btn-add-to-cart {
        font-size: 14px !important;
        height: 48px !important;
        margin-top: 16px !important;
      }

      .input-note-edit-order {
        position: relative;
        margin-bottom: 6px;
        .icon-note-product-detail {
          position: absolute;
          bottom: 25%;
          left: 2.5%;
        }

        .product-note {
          width: 100%;
          height: 60px;
          color: #b1b1b1;
          border-radius: 8px;
          font-size: 16px;
          padding: 20px 16px 20px 50px;
          border: 1px solid #e6e6e6;
        }
        .product-note:focus {
          border: 1px solid #e6e6e6;
        }
      }

      .description-product {
        font-size: 14px;
        margin-top: 25.5px;
        text-align: justify;
        color: #959595;
      }

      .product-price {
        margin-top: 20px;
        display: flex;
        align-items: center;
        .product-price-left {
          width: max-content;
          white-space: nowrap;
          display: flex;
          align-self: center;
          align-items: baseline;
          .product-price-discount {
            font-size: 17.7px;
            line-height: 22px;
            font-weight: 700;
            color: #ffa318;
          }
          .product-price-original {
            margin-right: 12px;
            font-size: 13.27px;
            line-height: 16px;
            font-weight: 500;
            color: #959595;
            text-decoration: line-through;
            margin-right: 12px;
          }
        }
        .product-price-right {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          width: 100%;
          padding: 0 10px;
          .product-price-btn-increase,
          .product-price-btn-decrease {
            width: 29.5px;
            height: 29.5px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            font-size: 20px;
            font-weight: bold;
            cursor: pointer;
            align-items: center;
          }

          .product-price-btn-decrease {
            background: #ebebeb;
            color: #51526c;
            svg > rect {
              fill: #ebebeb;
            }
          }
          .product-price-btn-increase {
            background: #db4d29;
            color: #fff;
          }

          .product-price-quantity {
            margin: 0 34.37px;
            font-style: normal;
            font-weight: 700;
            font-size: 13.5px;
            line-height: 16px;
          }
        }
      }

      .group-multiple-price,
      .group-product-combo {
        margin-top: 0 !important;
      }
    }
  }
  .edit-order-combo-theme-pho-viet-section #editOrderComboDetailBody {
    .edit-order-theme-pho-viet-section-group {
      .group-product-option-content {
        .group-product-option-item .option-level-value {
          .option-level-value-item .container-radio-option {
            font-size: 12px !important;
          }
        }
      }
      .group-product-option .group-collapse,
      .group-product-topping .group-collapse {
        .ant-collapse-item .ant-collapse-header {
          span.ant-collapse-header-text {
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
          }
        }
      }
    }
  }
}

.detail-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.w-50 {
  max-width: 100%;
  min-width: 1178px;
}

.w-100 {
  width: 100%;
}

.display-none {
  display: none !important;
}

.cart-edit-item {
  .ant-modal-body {
    height: 100%;
  }
}
.modal-product-cart-detail {
  height: calc(100vh - 64px) !important;
}
@media (max-width: 500px) {
  .description-edit-order-item div {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  }
}
