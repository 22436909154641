.order-card {
  margin-bottom: 36px;
  width: 100%;
  .order-card-header {
    width: 100%;
    padding: 12px 16px;
    background: #f9f9f9;
    border-radius: 20px 20px 0px 0px;

    .order-card-header-left {
      display: flex;
      .icon {
        display: flex;
        width: 44px;
        height: 44px;
        align-items: center;
        svg {
          width: 100%;
          height: 100%;
        }
      }
      .order-info {
        padding-left: 12px;
        .my-order-delivery-name {
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 20px;
          text-transform: uppercase;
          color: #959595;
        }
        .my-order-code {
          height: 28px;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 28px; /* 140% */
          display: flex;
          align-items: center;
          color: #282828;
        }
        .my-order-code:hover {
          cursor: pointer;
        }
        .my-order-status {
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 20px;
          display: flex;
          color: #ff8c21;
        }
      }
    }
    .order-card-header-right {
      display: flex;
      justify-content: flex-end;
      .order-info {
        .platform {
          height: 36px;

          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 20px;
          display: flex;
          align-items: center;
          text-align: right;
          letter-spacing: 0.3px;
          color: #282828;
          justify-content: flex-end;
        }
        .time-order {
          height: 20px;

          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          display: flex;
          align-items: center;
          text-align: right;
          color: #959595;
          justify-content: flex-end;
        }
      }
    }
  }
  .order-card-content {
    min-height: 104px;
    background: #ffffff;
    padding: 12px 16px;
    $order-item-content-width: 80px;
    .order-item-image {
      float: left;
      width: $order-item-content-width;
      height: $order-item-content-width;
    }
    .mobile-order-item-image {
      display: none;
    }
    .order-item-content {
      width: 100%;
      .order-item-name {
        padding-left: 24px;

        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        height: 50px;
        text-transform: capitalize;
        color: #282828;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .order-item-quantity {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        text-align: center;
        color: #282828;
        height: 36px;
      }
      .order-item-price {
        display: flex;
        justify-content: flex-end;
        height: 100%;
        .selling-price {
          height: 36px;

          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          text-align: right;
          color: #282828;
        }
        .original-price--percent-discount {
          display: flex;
          align-items: center;
          .original-price {
            height: 24px;

            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            display: flex;
            align-items: center;
            text-align: right;
            text-decoration-line: line-through;
            color: #959595;
          }
          .percent-discount {
            margin-left: 8px;
            height: 24px;
            padding: 3.5px 8px;
            background: #ffa318;
            border-radius: 20px;

            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            display: flex;
            align-items: center;
            color: #ffffff;
            text-decoration-line: none;
          }
        }
      }
      .topping,
      .option {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        display: flex;
        align-items: center;
        letter-spacing: 0.3px;
        color: #959595;
        padding-top: 8px;
      }
      .topping-name,
      .option-name {
        padding-left: 24px;
      }
      .topping-quantity,
      .option-level {
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
      .topping-price {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .topping-price-col {
          display: flex;
          flex-direction: column;
          .topping-price-value {
            display: flex;
            justify-content: flex-end;
          }
          .topping-original-price {
            
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            text-decoration-line: line-through;
            color: #959595;
            display: flex;
            justify-content: flex-end;
          }
        }
      }
      .combo-item-name {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        height: 28px;
        display: flex;
        align-items: center;
        letter-spacing: 0.3px;
        color: #282828;
        padding: 8px 0px 4px 24px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }
  }
  .order-card-total {
    border-top: 1px solid #f3f3f3;
    width: 100%;
    height: 60px;
    background-color: #ffffff;
    .total-order-items {
      padding-left: 16px;

      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      display: flex;
      align-items: center;
      color: #282828;
    }
    .total-price {
      padding-right: 16px;

      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      display: flex;
      align-items: center;
      text-align: right;
      color: #282828;
      justify-content: flex-end;
      .total-amount {
        padding-left: 4px;
        color: #db4d29;
      }
    }
  }
  .border-radius-bottom {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
  .order-card-footer {
    border-top: 1px solid #f3f3f3;
    height: 80px;
    background-color: #ffffff;
    border-radius: 0px 0px 20px 20px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    padding-right: 16px;
    .order-card-footer-cancel {
      padding: 16px;
      color: #FF2424;
      font-weight: 700;
      font-size: 16px;
    }
    .order-cancel-by-cashier {
      margin: 0 16px;

      span {
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: #FF2424;
      }
    }
  }
}
.order-card:last-child {
  margin-bottom: 0px;
}
/* Mobile - ant-xs */
@media (max-width: 575px) {
  .order-card {
    margin-bottom: 18px;
    .order-card-header {
      height: auto;
      .order-card-header-left .order-info {
        padding-left: 12px;
        display: grid;
        width: 100%;
        grid-template-columns: repeat(2, 1fr);
        gap: 12px;
        .my-order-status {
          display: flex;
          align-items: flex-start;
          gap: 10px;
          border-radius: 8px;
          position: absolute;
          right: 0px;
          .order-status-to-confirm{
            height: 36px;
            padding: 4px 8px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
    .order-card-content {
      min-height: 104px;
      background: #ffffff;
      padding: 12px 16px;
      $order-item-content-width: 60px;
      .order-item-image {
        display: none;
      }
      .mobile-order-item-image {
        display: block;
        float: left;
        width: $order-item-content-width;
        height: $order-item-content-width;
        .fnb-display-image {
          width: 60px;
        }
      }
      .order-item-content {
        width: 100%;
        .order-item-name {
          padding-left: 0px;

          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          height: 60px;
          text-transform: capitalize;
          color: #282828;
          display: flex;
          align-items: center;
          .mobile-order-item-name {
            padding-left: 16px;
            height: 42px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        }
        .order-item-quantity {
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          text-align: center;
          color: #282828;
          height: auto;
          padding-left: 25px;
        }
        .order-item-price {
          display: flex;
          justify-content: flex-end;
          height: 100%;
          .selling-price {
            height: 36px;

            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            text-align: right;
            color: #282828;
          }
          .original-price--percent-discount {
            display: flex;
            align-items: center;
            .original-price {
              height: 24px;

              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 17px;
              display: flex;
              align-items: center;
              text-align: right;
              text-decoration-line: line-through;
              color: #959595;
            }
            .percent-discount {
              margin-left: 8px;
              height: 24px;
              padding: 3.5px 8px;
              background: #ffa318;
              border-radius: 20px;

              font-style: normal;
              font-weight: 600;
              font-size: 14px;
              line-height: 17px;
              display: flex;
              align-items: center;
              color: #ffffff;
              text-decoration-line: none;
            }
          }
        }
        .topping,
        .option {
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          display: flex;
          align-items: center;
          letter-spacing: 0.3px;
          color: #959595;
          padding-top: 8px;
        }
        .topping-name,
        .option-name {
          padding-left: 0px;
        }
        .topping-quantity,
        .option-level {
          display: flex;
          align-items: center;
          justify-content: flex-end;
        }
        .topping-price {
          display: flex;
          align-items: center;
          justify-content: flex-end;
        }
        .combo-item-name {
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 20px;
          height: 28px;
          display: flex;
          align-items: center;
          letter-spacing: 0.3px;
          color: #282828;
          padding: 8px 0px 4px 0px;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }
      }
    }
    .order-card-total {
      border-top: 1px solid #f3f3f3;
      width: 100%;
      height: 84px;
      background-color: #ffffff;
      padding: 12px 16px;
      .total-order-items {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        color: #282828;
      }
      .total-price {
        padding-right: 0px;

        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        display: flex;
        align-items: center;
        text-align: right;
        color: #282828;
        justify-content: flex-end;
        .total-amount {
          padding-left: 4px;
          color: #db4d29;
        }
      }
    }
    .border-radius-bottom {
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
    }
    .order-card-footer {
      border-top: 1px solid #f3f3f3;
      height: auto;
      padding: 12px 16px;
      background-color: #ffffff;
      border-radius: 0px 0px 20px 20px;
      display: flex;
      flex-direction: column-reverse;
      justify-content: flex-end;
      align-items: center;
      padding-right: 16px;
      .order-cancel-by-cashier {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-bottom: 12px;
      }
    }
  }
}
