.upsert-modal-overlay {
  width: 100%;
  height: 100%;

  font-size: 14px !important;

  top: 0;
  left: 0;
  right: 0;
  left: 0;

  background-color: rgba(0, 0, 0, 0.25);

  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }

  button:hover {
    cursor: pointer;
  }

  .upsert-modal {
    height: fit-content;
    width: 568px;
    padding: 32px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
    border-radius: 12px;

    overflow: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    &::-webkit-scrollbar {
      display: none;
    }

    .modal-header {
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #db4d29;
      margin: 0;
      padding: 0;
      width: 100%;
      height: 50px;
      border-radius: 12px 12px 0 0;
      
      color: white;
    }
    .modal-body {
      box-sizing: border-box;
      width: 100%;
      padding: 36px 24px;
      overflow-y: auto;
      .name-adress, .address-detail {
        .ant-form-item-has-error {
          .ant-form-item-control {
            margin-bottom: 16px;
          }
        }
      }
      .ant-form-item-control-input-content > div {
        height: 60px;
      }
      .ant-form-item-explain-error {
        font-size: 14px;
        font-weight: 400;
      }
      .row-first {
        margin-bottom: 36px !important;
      }
      .row-last {
        padding-bottom: 0;
        margin-bottom: -36px;
      }
      .row-address {
        margin-bottom: -4px !important;
      }
      .row {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: -14px;
        span {
          font-size: 0.9em !important;
        }
        input {
          box-shadow: none;
          border: 1px solid #e2e2e2;
          border-radius: 12px;
        }
        &.map-select-input {
          span.fnb-input.select-address-input {
            box-shadow: none;
            border: 1px solid #e2e2e2;
            border-radius: 12px;
            input {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
        &.gg-map {
          margin-top: 10px;
          margin-bottom: 24px;
          width: 456px;
          height: 232px;
        }
      }
      .col-3 {
        flex: 3;
        min-width: 130px;
      }
      .fnb-form-label {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #000000;
      }
      .ant-lable-mt {
        margin-top: -10px;
      }
      .col-9 {
        flex: 9;
        input.ant-input {
          font-size: 14px;
        }
      }
      .radio-toolbar input[type="radio"] {
        display: none;
      }
      .radio-toolbar label {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;

        color: #969696;
        background: #ffffff;
        border: 1px solid #e2e2e2;
        border-radius: 26.4183px;
        display: inline-block;
        padding: 8px 17px;
        &:not(:first-of-type) {
          margin-left: 10px;
        }
        cursor: pointer;
      }
      .radio-toolbar input[type="radio"]:checked + label {
        background: #db4d29;
        color: white;
      }

      .radio-toolbar input[type="radio"]:disabled + label {
        background-color: #bbb;
      }
      .map-select-input {
        .address-popover {
          box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
          max-height: 300px;
          overflow-y: scroll;
        }
      }
      .google-map-box {
        width: 100%;
        height: 100%;
      }
    }
    .modal-footer {
      padding-bottom: 12px;
      display: flex;
      justify-content: space-around;
      gap: 18px;
      align-items: center;
      .btn {
        min-width: 100px;
        height: 50px;
        border: 1px solid #e2e2e2;
        border-radius: 12px;
        background-color: white;
        color: #282828;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        padding: 12.5px 32.5px;
        &.btn-submit {
          background-color: #db4d29;
          color: white;
        }
      }
    }
    .disable {
      background-color: rgba(255, 255, 255, 0.5) !important;
    }
  }
}
@media only screen and (max-width: 600px) {
  .upsert-modal-overlay {
    font-size: 18px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: fixed;
    .upsert-modal {
      width: 100%;
      min-height: 60%;
      max-height: 90%;
      margin: 0;
      display: flex;
      justify-content: space-between;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      .modal-header {
        height: 60px;
      }
      .modal-body {
        flex: 1;
        .row {
          &:not(:first-child) {
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;

            .col-9,
            .col-3 {
              flex: 1;
              width: 100%;
            }

            &.gg-map {
              width: 100%;
            }
          }
        }
        .row.map-select-input {
          position: relative;
          .address {
            .ant-row .ant-col {
              position: unset;
              .ant-form-item-control-input {
                position: unset;
              }
            }
          }
          .address-popover {
            position: unset;
            left: 0;
            right: 0;
            box-shadow: none;
            .address-popover-item {
              padding: auto;
            }
          }
        }
      }
      .modal-footer {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 0;
        padding: 0;
        z-index: 1;
        button.btn {
          flex: 1;
          width: 100%;
          border-radius: 0px;
          border-bottom: none;
          font-size: 16px;
          font-weight: 500;
          
        }
      }
    }
  }
}
