.edit-order-theme-pho-viet-section {
  width: 100%;
  height: 100%;

  .edit-order-theme-pho-viet-section-group {
    display: flex;
    max-width: 100%;
    background-attachment: initial;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center !important;
    margin: auto;
    padding: unset;
    gap: 35.59px;
    width: 100%;
    height: 100%;

    .edit-order-theme-pho-viet-section-left {
      width: 41%;
      height: 100%;
      padding-top: 16px;
      position: relative;

      .product-detail-image-swiper {
        width: 100%;
        height: 100%;
        border: 1px solid #e6e6e6;
        border-radius: 20px;
        aspect-ratio: 1/1;

        .swiper-button-prev,
        .swiper-button-next {
          color: #51526c !important;
        }

        .swiper-wrapper {
          .swiper-slide {
            display: flex;
            align-items: center;
            justify-content: center;
            .image-zoom-in {
              width: 50% !important;
              height: 50% !important;
            }
          }
        }
      }

      .slider-product-detail {
        width: 100%;
        height: 570px;
        border: 1px solid #e6e6e6;
        border-radius: 20px;
        display: inline-flex !important;
        align-items: center;
        justify-content: center;
        .product-detail-slider-image {
          width: 100%;
          display: inline-flex !important;
          align-items: center;
          justify-content: center;
          height: 100%;
          .image-product-detail {
            width: 386px;
            height: 379px;
          }
        }
        .slick-arrow {
          width: 18.75px;
          height: 37.5px;
          z-index: 9999;
        }
        .slick-next {
          right: 4%;
        }
        .slick-prev {
          left: 4%;
        }
      }

      .swiper-small-image {
        .swiper-wrapper {
          .swiper-slide {
            width: 130px !important;
            height: 130px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 20px;
            border: 1px solid #e6e6e6;

            .image-zoom-out {
              width: 113px;
              height: 113px;
              border: 1px solid #e6e6e6;
              border-radius: 20px;
            }
          }

          .swiper-slide-thumb-active {
            border: 1px solid #db4d29;
          }
        }
      }

      .discount-edit-order {
        position: absolute;
        width: fit-content;
        height: fit-content;
        padding: 8px 16px;
        max-width: max-content;
        max-height: max-content;
        font-weight: 500;
        background: #ffa318;
        border-radius: 50px;
        color: #fff;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 9;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px;
        margin: 16px 0px 0px 16px;
      }

      .mySwiper {
        width: 100%;
      }

      .ant-image,
      .ant-image-error {
        width: 100%;
        height: 100%;
        aspect-ratio: 1/1;
        border-radius: 50px;
        background-color: white;

        .product-image {
          width: 100%;
          height: 100%;
          aspect-ratio: 1/1;
          border-radius: 50px;
          padding: 16px;
          object-fit: cover;
        }
      }

      .border-image-thumnail {
        border: 1px solid #e6e6e6;
        border-radius: 20px;
        position: relative;
      }
      .image-sub-group {
        margin-top: 24px;
        width: fit-content;
        .ant-image {
          .image-sub {
            padding: 8px;
            width: 130px;
            height: 130px;
            border-radius: 20px;
            border: 1px solid #db4d29;
            background-color: white;
            object-fit: cover;
          }
        }
      }
    }

    .edit-order-theme-pho-viet-section-right {
      width: 59%;
      height: 100%;
      .select-edit-order {
        margin-top: 24px;
      }
      ::-webkit-scrollbar {
        width: 5px;
      }

      ::-webkit-scrollbar-thumb {
        background: #c2d4e5 !important;
        border-radius: 8px;
      }

      ::-webkit-scrollbar-track {
        background: transparent;
      }
      .custom-margin-top {
        margin-top: 50.68px;
      }
      .flash-sale-banner {
        margin: 0;
        margin-bottom: 24px;
      }
      .product-name {
        width: 100%;
        font-size: 24px;
        font-weight: 700;
        margin-bottom: unset;
        line-height: 32px;
      }

      .group-star-rate {
        display: flex;
        align-items: center;
        li.ant-rate-star.ant-rate-star-full {
          margin-inline-end: 2px;
        }
        .total-review {
          margin-left: 12px;
          line-height: 31.42px;
          text-transform: uppercase;

          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          margin-top: 10px;
        }
        .ant-rate {
          font-size: 32px;
          svg {
            width: 32px;
            height: 32px;
          }
        }
      }

      .description-product {
        font-size: 14px;
        margin-top: 25.5px;
        text-align: justify;
        color: #959595;
      }

      .product-price {
        margin-top: 20px;
        display: flex;
        align-items: center;
        .product-price-left {
          width: max-content;
          white-space: nowrap;
          .product-price-discount {
            margin-left: 12px;
            font-size: 24px;
            font-weight: 700;
            color: #ffa318;
          }
          .product-price-original {
            font-size: 18px;
            font-weight: 500;
            color: #959595;
            text-decoration: line-through;
          }
        }
        .product-price-right {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          width: 100%;
          padding: 0 10px;
          .product-price-btn-increase,
          .product-price-btn-decrease {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            font-size: 20px;
            font-weight: bold;
            align-items: center;
          }

          .active-click {
            cursor: pointer;
          }
          .prevent-click {
            cursor: no-drop !important;
          }

          .product-price-btn-decrease {
            background: #ebebeb;
            color: #51526c;
            svg > rect {
              fill: #ebebeb;
            }
          }
          .product-price-btn-increase {
            background: #db4d29;
            color: #fff;
          }

          .product-price-quantity {
            margin: 0 46.5px;

            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 28px;
            min-width: 24px;
            display: flex;
            justify-content: center;
          }
        }
      }

      .product-description {
        color: #959595;
        font-size: 14px;
        margin-top: 20px;
        text-align: justify;

        .ant-typography {
          .ant-typography-expand {
            color: #db4d29;
            font-size: 14px;
          }
        }
      }

      .group-multiple-price {
        width: 100%;
        .group-collapse {
          border: unset;
          border-radius: 8px;

          .ant-collapse-item {
            border: unset;

            .ant-collapse-header {
              background: #f9f9f9;
              font-size: 20px;
              font-weight: bold;
              color: #000000;
              border-radius: 8px;
              line-height: 28px;
              .ant-collapse-header-text {
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                text-transform: uppercase;
              }
            }
            .ant-collapse-content {
              border: unset;
              .ant-collapse-content-box {
                padding: unset !important;

                .group-multiple-price-content {
                  display: grid;
                  align-items: center;

                  .ant-radio-group {
                  }
                  .group-multiple-price-item {
                    height: fit-content;
                    width: 100%;
                    display: inline-flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: unset;
                    border-bottom: 1px solid #e6e6e6;

                    .price-name {
                      height: 100%;
                      display: grid;
                      align-items: center;
                      padding: 8px 0;
                      margin-left: 16px;

                      .name {
                        font-size: 16px;
                        font-weight: 500;
                      }

                      .price-name-value {
                        font-size: 14px;
                        font-weight: 400;
                      }
                    }

                    .checkbox-price {
                      margin-right: 16px;
                    }

                    .price-name-value-radio {
                      width: 24px;
                      height: 24px;
                    }
                  }
                }

                .group-multiple-price-content *:last-child {
                  border: unset;
                }
              }
            }
          }
        }
      }

      .group-product-option {
        width: 100%;
        margin-top: 12px;

        .group-collapse {
          border: unset;
          border-radius: 8px;

          .ant-collapse-item {
            border: unset;

            .ant-collapse-header {
              background: #f9f9f9;
              font-size: 20px;
              font-weight: bold;
              color: #000000;
              border-radius: 8px;
              .ant-collapse-header-text {
                text-transform: uppercase;
              }
            }
            .ant-collapse-content {
              border: unset;
              .ant-collapse-content-box {
                padding: unset !important;

                .group-product-option-content {
                  display: grid;
                  align-items: center;
                  padding: 0 16px;
                  justify-content: stretch;

                  .group-product-option-item {
                    height: fit-content;
                    width: 100%;
                    display: inline-flex;
                    align-items: center;
                    padding: unset;
                    border-bottom: 1px solid #e6e6e6;
                    justify-content: space-between;

                    .option-name {
                      font-size: 16px;
                      font-weight: 600;
                    }

                    .option-level-value {
                      width: fit-content;
                      display: flex;
                      align-items: center;
                      flex-wrap: wrap;
                      justify-content: flex-end;
                      margin: 8px;
                      gap: 10px;

                      .option-level-value-item {
                        height: 32px;
                        border-radius: 7px;
                        background: #fff;
                        color: #000;
                        font-size: 12px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-weight: 500;
                        border: unset;
                        white-space: nowrap;

                        .ant-radio-button-checked {
                          border-radius: 7.5px;
                        }

                        .container-radio-option {
                          display: flex;
                          height: fit-content;
                          margin-left: 0;
                          padding-left: 0;
                          font-size: 16px;
                          font-style: normal;
                          font-weight: 500;
                          line-height: 24px;
                        }

                        .dot-select-product-option {
                          font-size: 35px;
                          position: relative;
                          top: -10px;
                          left: -7px;
                        }
                        .ant-radio-button:last-child {
                          border: unset;
                        }
                      }

                      .ant-radio-button-wrapper-checked {
                        color: #fff;
                        font-size: 14px;
                      }

                      .d-flex-prefix {
                        display: flex !important;
                      }

                      .option-level-value-item:not(:first-child)::before {
                        background-color: unset !important;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      .group-product-topping {
        margin-top: 12px;
        width: 100%;

        .group-collapse {
          border: unset;
          border-radius: 8px;

          .ant-collapse-item {
            border: unset;

            .ant-collapse-header {
              background: #f9f9f9;
              font-size: 16px;
              font-weight: bold;
              color: #000000;
              border-radius: 8px;
              .ant-collapse-header-text {
                text-transform: uppercase;
              }
            }
            .ant-collapse-content {
              border: unset;
              .ant-collapse-content-box {
                padding: unset !important;

                .group-product-topping-content {
                  display: grid;
                  align-items: center;

                  .group-product-topping-item {
                    height: fit-content;
                    width: 100%;
                    display: inline-flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: unset;
                    border-bottom: 1px solid #e6e6e6;
                    gap: 10px;

                    .topping-name {
                      height: 100%;
                      display: grid;
                      align-items: center;
                      padding: 8px 0;
                      margin-left: 16px;

                      .name {
                        font-size: 16px;
                        font-weight: 600;
                        word-break: break-word;
                      }

                      .topping-price-value {
                        font-size: 14px;
                        font-weight: 400;
                      }

                      .topping-original-price-value {
                        color: #959595;

                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 20px;
                        text-decoration-line: line-through;
                        padding-right: 12px;
                      }
                    }

                    .topping-change-quantity {
                      display: inline-flex;
                      align-items: center;
                      justify-content: end;
                      margin-right: 16px;

                      .topping-quantity-btn-increase,
                      .topping-quantity-btn-decrease {
                        width: 24px;
                        height: 24px;
                        border-radius: 50%;
                        display: flex;
                        align-items: self-end;
                        justify-content: center;
                        font-weight: bold;
                        cursor: pointer;
                        align-items: center;
                      }

                      .topping-quantity-btn-decrease {
                        background: #f9f1df;
                        color: #51526c;
                        svg > rect {
                          fill: #ebebeb;
                        }
                      }

                      .topping-quantity-btn-increase {
                        background: #db4d29;
                        color: #fff;
                      }

                      .topping-quantity-value {
                        margin: 0 20px;

                        font-style: normal;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 24px;
                      }
                    }
                  }
                }

                .group-product-topping-content *:last-child {
                  border: unset;
                }
              }
            }
          }
        }
      }

      .input-note-edit-order {
        position: relative;

        .icon-note-product-detail {
          position: absolute;
          bottom: 25%;
          left: 2.5%;
        }
        .product-note {
          margin-top: 14px;
          width: 100%;
          min-height: 56px;
          color: #282828;
          border-radius: 8px;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          padding: 20px 16px 20px 50px;
          border: 1px solid #e6e6e6;

          .ant-input-prefix {
            padding-right: 5px;
          }
        }
        .product-note:focus {
          border: 1px solid #e6e6e6;
        }
        .note-icon {
          position: relative;
          top: 65px;
          left: 10px;
          margin-left: 5px;
          z-index: 998;
        }
      }

      .btn-add-to-cart {
        width: 100%;
        height: 60px;
        background: #db4d29;
        margin-top: 24px;
        border-radius: 12px;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: unset;
        font-size: 20px;
        font-weight: 500;

        .btn-add-to-cart-text {
          display: flex;
          align-items: center;
          font-weight: 700;
          margin-right: 20px;

          .icon-check-out {
            margin-left: 10px;
          }
        }
        .btn-add-to-cart-price {
          display: flex;
          flex-direction: row;
          .btn-add-to-cart-origin-price-value {
            text-decoration: line-through;
            font-weight: 400;
            margin-left: 16px;
          }

          .btn-add-to-cart-price-value {
            margin-left: 12px;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 28px;
          }
        }
      }

      .btn-add-to-cart:hover {
        cursor: pointer;
        border: 1px solid #db4d29;
        font-weight: 500;
      }

      .margin-top {
        margin-top: 12px !important;
      }

      .group-product-combo {
        margin-top: 24px;
        width: 100%;

        .group-collapse {
          border: unset;
          border-radius: 8px;

          .product-name-banner {
            border: unset;

            .ant-collapse-header {
              background: #f9f9f9;
              font-size: 16px;
              font-weight: bold;
              color: #000000;
              border-radius: 8px;
              .ant-collapse-header-text {
                color: #959595;
                text-transform: uppercase;
              }
            }

            .ant-collapse-content {
              border: unset;

              .ant-collapse-content-box {
                padding: 12px 0 0 0 !important;
                .group-product-option,
                .group-product-topping {
                  margin-top: unset;

                  .group-collapse {
                    .ant-collapse-item {
                      .ant-collapse-header {
                        background: #f9f9f9;
                        color: #959595;
                      }

                      .ant-collapse-content {
                        .ant-collapse-content-box {
                          padding-top: unset !important;
                        }
                      }
                    }
                  }
                }

                .group-product-topping {
                  margin-top: 12px;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 500px) {
  .edit-order-theme-pho-viet-section {
    width: 100%;

    .edit-order-theme-pho-viet-section-group {
      flex-direction: column;
      min-width: 100px;
      gap: 21.41px;
      .flash-sale-banner {
        margin: 0px;
        margin-bottom: 10px;
      }
    }
    .edit-order-theme-pho-viet-section-left {
      padding: 0;
      width: 100% !important;
    }
    .edit-order-theme-pho-viet-section-right {
      width: 100% !important;
    }
    .total-cart {
      position: absolute;
      padding: 0 16px 16px 16px;
      z-index: 99999;
      background: #fff;
      bottom: 0;
      left: 0;
      right: 0;
      height: auto !important;
    }

    .btn-add-cancel {
      background: #ffffff;
      border: 1px solid #e2e2e2;
      color: #000;
      justify-content: center;
      width: 100%;
      height: 60px;
      margin-top: 16px;
      border-radius: 12px;
      display: flex;
      align-items: center;
      padding: unset;
      font-size: 16px;
      font-weight: 500;
      height: 48px !important;
    }
    .btn-add-to-cart {
      font-size: 16px !important;
      height: 48px !important;
      margin-top: 16px !important;
      .btn-add-to-cart-price-value {
        margin-left: 8px !important;
        font-weight: 500 !important;
      }
      .btn-add-to-cart-text {
        margin-right: 8px !important;
        font-weight: 500 !important;
        font-size: 16px !important;
        .icon-check-out {
          margin-left: 8px !important;
        }
      }
    }

    .input-note-edit-order {
      position: relative;
      margin-bottom: 6px;

      .icon-note-product-detail {
        position: absolute;
        bottom: 25%;
        left: 2.5%;
      }

      .product-note {
        width: 100%;
        height: 60px;
        color: #b1b1b1;
        border-radius: 8px;
        font-size: 16px;
        padding: 20px 16px 20px 50px;
        border: 1px solid #e6e6e6;
      }
      .product-note:focus {
        border: 1px solid #e6e6e6;
      }
    }

    .product-detail-image-swiper {
      width: 100%;
      height: 100%;
      border: 1px solid #e6e6e6;
      border-radius: 20px;
      aspect-ratio: 1/1;

      .swiper-button-prev,
      .swiper-button-next {
        color: #51526c !important;
      }

      .swiper-wrapper {
        .swiper-slide {
          display: flex;
          align-items: center;
          justify-content: center;
          .image-zoom-in {
            width: 50% !important;
            height: 50% !important;
          }
        }
      }
    }

    .slider-product-detail {
      width: 100%;
      height: 570px;
      border: 1px solid #e6e6e6;
      border-radius: 20px;
      display: inline-flex !important;
      align-items: center;
      justify-content: center;
      .product-detail-slider-image {
        width: 100%;
        display: inline-flex !important;
        align-items: center;
        justify-content: center;
        height: 100%;
        .image-product-detail {
          width: 386px;
          height: 379px;
        }
      }
      .slick-arrow {
        width: 18.75px;
        height: 37.5px;
        z-index: 9999;
      }
      .slick-next {
        right: 4%;
      }
      .slick-prev {
        left: 4%;
      }
    }

    .swiper-small-image {
      .swiper-wrapper {
        .swiper-slide {
          width: 130px !important;
          height: 130px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 20px;
          border: 1px solid #e6e6e6;

          .image-zoom-out {
            width: 113px;
            height: 113px;
            border: 1px solid #e6e6e6;
            border-radius: 20px;
          }
        }

        .swiper-slide-thumb-active {
          border: 1px solid #db4d29;
        }
      }
    }

    .discount-edit-order {
      position: absolute;
      max-width: max-content;
      max-height: max-content;
      font-weight: 500;
      background: #ffa318;
      border-radius: 16px;
      color: #fff;
      min-width: 50px;
      text-align: center;
      min-height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 9;
      padding: 0px 8px;
    }

    .mySwiper {
      width: 100%;
    }

    .ant-image,
    .ant-image-error {
      width: 100%;
      height: 100%;
      aspect-ratio: 1/1;
      border-radius: 20px;
      background-color: white;

      .product-image {
        width: 100%;
        height: 100%;
        aspect-ratio: 1/1;
        border-radius: 20px;
        border: 1px solid #e6e6e6;
        background-color: white;
        object-fit: cover;
      }
    }

    .group-star-rate {
      display: flex;
      align-items: center;
      li.ant-rate-star.ant-rate-star-full {
        margin-inline-end: 2px;
      }
      .total-review {
        margin-left: 12px;
        line-height: 31.42px;
        text-transform: uppercase;

        font-style: normal;
        font-weight: 500;
        font-size: 12.8353px;
      }
      .ant-rate {
        font-size: 16px;
        svg {
          width: 16px;
          height: 16px;
        }
      }
    }

    .product-price {
      margin-top: 8px;
      display: flex;
      align-items: center;
      .product-price-left {
        width: max-content;
        white-space: nowrap;
        display: flex;
        align-self: center;
        align-items: baseline;
        .product-price-discount {
          font-size: 17.7px;
          line-height: 22px;
          font-weight: 700;
          color: #ffa318;
        }
        .product-price-original {
          margin-right: 12px;
          font-size: 13.27px;
          line-height: 16px;
          font-weight: 500;
          color: #959595;
          text-decoration: line-through;
        }
      }
      .product-price-right {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        padding: 0 10px;
        .product-price-btn-increase,
        .product-price-btn-decrease {
          width: 29.5px;
          height: 29.5px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          font-size: 20px;
          font-weight: bold;
          cursor: pointer;
          align-items: center;
        }

        .product-price-btn-decrease {
          background: #ebebeb;
          color: #51526c;
          svg > rect {
            fill: #ebebeb;
          }
        }
        .product-price-btn-increase {
          background: #db4d29;
          color: #fff;
        }

        .product-price-quantity {
          margin: 0 34.37px;

          font-style: normal;
          font-weight: 700;
          font-size: 13.5px;
          line-height: 16px;
        }
      }
    }

    .group-multiple-price {
      margin-top: 0 !important;
    }
  }
  .ant-drawer-content-wrapper {
    .ant-drawer-body {
      padding: 16px;
    }
    .drawer-product-cart-detail,
    .box-drawer-pos-theme2 {
      * {
        font-family: inherit;
      }
      .close-branch-theme-2 {
        padding-bottom: 16px;
      }
    }
  }
  .option-level-value-item {
    white-space: normal !important;
    height: auto !important;
  }
  .edit-order-theme-pho-viet-section {
    .edit-order-theme-pho-viet-section-group {
      .group-product-option-content {
        .group-product-option-item .option-level-value {
          .option-level-value-item .container-radio-option {
            font-size: 12px !important;
          }
        }
      }

      .group-product-option .group-collapse,
      .group-product-topping .group-collapse {
        .ant-collapse-item .ant-collapse-header {
          .ant-collapse-header-text {
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
          }
        }
      }
    }
  }
}
@media (max-width: 1024px) {
  .edit-order-theme-pho-viet-section {
    .edit-order-theme-pho-viet-section-group {
      flex-direction: column;
      .edit-order-theme-pho-viet-section-left {
        width: 100%;
      }
      .edit-order-theme-pho-viet-section-right {
        width: 100%;
      }
    }
  }
}

@media (max-width: 420px) {
  .edit-order-theme-pho-viet-section {
    .btn-add-to-cart {
      font-size: 14px !important;
      line-height: 20px;
      .btn-add-to-cart-price {
        flex-direction: column !important;
        .btn-add-to-cart-price-value {
          font-size: 16px !important;
          margin-left: 16px !important;
          font-weight: 500 !important;
        }
      }
      .btn-add-to-cart-text {
        margin-right: 10px !important;
        font-weight: 500 !important;
        font-size: 16px !important;
        .icon-check-out {
          margin-left: 5px !important;
        }
      }
    }
  }
}

.detail-center {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100% !important;
}

.w-50 {
  max-width: 100%;
  min-width: 1178px;
}

.w-100 {
  width: 100%;
}

.display-none {
  display: none !important;
}
.overflow-item-detail {
  overflow: auto;
  height: calc(100% - 85px);
}
.total-cart {
  height: 60px;
}

@media (max-width: 500px) {
  .description-edit-order-item div {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  }
}
